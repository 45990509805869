<template>
  <div class="header-container" ref="headerContainer">
    <div class="title">Sonny Tracker</div>
    <TrackerSearch/>
  </div>
</template>

<script>
import TrackerSearch from '../Tracker/TrackerSearch.vue'

export default {
  components: {
    TrackerSearch,
  }
}
</script>

<style scoped>
.header-container {
  background: rgb(243, 165, 170);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-container .title {
  font-family: "ChennaiBlack";
  font-weight: 1000;
  font-size: 40px;
  color: white;
  cursor: default;
  transition: all ease 0.2s;
}

.header-container .title:hover {
  transform: scale(1.025);
}

.flag .fi-fr.fis {
  width: 100%;
  height: 100%;
  /* background-size: cover; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.top-right-button,
.top-left-button {
  position: absolute;
  width: 32px;
  top: 3.5rem;
}

.top-right-button-game,
.top-left-button-game {
  position: absolute;
  width: 32px;
  top: 3.5rem;
}

.top-right-button {
  left: calc(100vw / 2 + 165px);
}

.top-left-button {
  right: calc(100vw / 2 + 165px);
}

.top-right-button-game {
  left: calc(100vw / 2 + 145px);
}

.top-left-button-game {
  right: calc(100vw / 2 + 145px);
}

@media screen and (max-width: 440px) {
  .top-right-button,
  .top-left-button,
  .top-right-button-game,
  .top-left-button-game {
    top: 13vw;
    width: 8vw;
  }

  .top-right-button {
    left: 87%;
  }

  .top-left-button {
    right: 87%;
  }

  .top-right-button-game {
    left: 84%;
  }

  .top-left-button-game {
    right: 84%;
  }
}

.top-right,
.top-left {
  transition: left ease 0.2s, right ease 0.2s;
  /* border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  box-shadow: rgb(0 0 0) 0px 0px 15px; */
}

.top-right-button img,
.top-right-button-game img,
.top-left-button img,
.top-left-button-game img {
  opacity: 0.7;
  transition: opacity ease 0.2s, width ease 0.2s;
  width: 100%;
}

.top-right-button img:hover,
.top-right-button-game img:hover,
.top-left-button img:hover,
.top-left-button-game img:hover {
  opacity: 1;
  cursor: pointer;
}

.streak-clickable:hover {
  cursor: pointer;
}

.flag-header {
  width: 100% !important;
  /* margin: 0px auto !important; */
  /* position: absolute;
  left: 0;
  top: -21px; */
  transform: scale(1.7, 1.2);
  box-shadow: none;

  opacity: 0.8;
  transition: opacity ease 0.1;
}

.flag-header:hover {
  opacity: 1;
  cursor: pointer;
}
</style>