<template>
  <div class="tracker-container">
    <TrackerGrid/>
  </div>
</template>

<script>
import TrackerSearch from "../components/Tracker/TrackerSearch.vue";
import TrackerGrid from "../components/Tracker/TrackerGrid.vue";

export default {
  data() {
    return {
      filter: "",
    };
  },
  components: {
    TrackerSearch,
    TrackerGrid,
  },
};
</script>

<style scoped>
.tracker-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 1.5rem;
}

.type-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.type-container h1 {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.category-container {
  display: flex;
  flex-direction: column;
}

.category-container h2 {
  margin: 10px;
  padding: 0;
  font-weight: 400;
}
</style>