<template>
  <div id="grid-top-container">
    <Loader v-if="loading" size="80px" />
    <div id="grid-container" v-else>
      <TrackerItem
        class="grid-item"
        v-for="item in filteredProducts"
        :key="item.name + item.url"
        :item="item"
        :categoryColor="getCategoryColor(item.category)"
        @sub="sub(item)"
        @add="add(item)"
        @fav="fav(item)"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import bus from "../../../tools/bus/bus";

import categoryColors from "../../../data/categoryColors";
import TrackerItem from "./TrackerItem.vue";
import Loader from "../General/Loader.vue";

export default {
  data() {
    return {
      allProducts: [],
      categoryColors: categoryColors,
      productsOwnedLocal: [],
      productsFavedLocal: [],
      loading: false,
    };
  },
  async created() {
    bus.$on("webResume", this.loadRemoteProductsSilently);
    await this.loadRemoteProducts();
    this.$store.dispatch("updateNbProducts", this.filteredProducts.length);
    this.loadProductsOwnedLocal();
    this.loadProductsFavedocal();
  },
  computed: {
    topFilter() {
      return this.$store.state.products.topFilter;
    },
    filter() {
      return this.$store.state.products.filter;
    },
    filteredProducts() {
      const searchLower = this.filter.toLowerCase();

      let allFilteredProducts = [];

      this.allProducts.forEach((typeObj) => {
        typeObj.categories.forEach((categoryObj) => {
          categoryObj.products.forEach((product) => {
            if (
              product.name.toLowerCase().includes(searchLower) ||
              categoryObj.category.toLowerCase().includes(searchLower) ||
              typeObj.type.toLowerCase().includes(searchLower)
            ) {
              const ownedObject = this.productsOwnedLocal.find(
                (p) => p.id === product.id
              );
              const count = ownedObject ? ownedObject.count : 0;

              const faved = this.productsFavedLocal.includes(product.id);

              allFilteredProducts.push({
                ...product,
                type: typeObj.type,
                category: categoryObj.category,
                count,
                faved,
              });
            }
          });
        });
      });

      if (this.topFilter === "owned") {
        return allFilteredProducts.filter((i) => i.count > 0);
      }
      if (this.topFilter === "faved") {
        return allFilteredProducts.filter((i) =>
          this.productsFavedLocal.includes(i.id)
        );
      }
      return allFilteredProducts;
    },
    colorPerCategory() {
      const result = [];

      let colorIndex = 0;
      this.allProducts.forEach((p) => {
        p.categories.forEach((c) => {
          const categoryWithColor = {
            category: c.category,
            color: this.categoryColors[colorIndex % this.categoryColors.length],
          };
          colorIndex++;
          result.push(categoryWithColor);
        });
      });

      return result;
    },
  },
  methods: {
    async loadRemoteProducts() {
      this.loading = true;
      const result = await axios.get(
        "https://sonnytrackercache.pages.dev/allProducts.json"
      );
      this.allProducts = result.data;
      this.loading = false;
    },
    async loadRemoteProductsSilently() {
      // console.log("Loading silently")
      if (this.loading)
        return
      const result = await axios.get(
        "https://sonnytrackercache.pages.dev/allProducts.json"
      );
      this.allProducts = result.data;
    },
    loadProductsOwnedLocal() {
      this.productsOwnedLocal =
        JSON.parse(localStorage.getItem("productsOwned")) || [];
    },
    loadProductsFavedocal() {
      this.productsFavedLocal =
        JSON.parse(localStorage.getItem("productsFaved")) || [];
    },
    updateLocalStorageOwned() {
      localStorage.setItem(
        "productsOwned",
        JSON.stringify(this.productsOwnedLocal)
      );
    },
    updateLocalStorageFaved() {
      localStorage.setItem(
        "productsFaved",
        JSON.stringify(this.productsFavedLocal)
      );
    },
    sub(item) {
      if (item.count > 0) {
        const ownedObject = this.productsOwnedLocal.find(
          (p) => p.id === item.id
        );
        if (!ownedObject) return;
        ownedObject.count--;
        this.productsOwnedLocal = this.productsOwnedLocal.filter(
          (p) => p.count > 0
        );
        this.updateLocalStorageOwned();
      }
    },
    add(item) {
      const ownedObject = this.productsOwnedLocal.find((p) => p.id === item.id);
      if (ownedObject) ownedObject.count++;
      else {
        this.productsOwnedLocal.push({
          id: item.id,
          count: 1,
        });
      }
      this.updateLocalStorageOwned();
    },
    fav(item) {
      if (this.productsFavedLocal.includes(item.id)) {
        this.productsFavedLocal = this.productsFavedLocal.filter(
          (p) => p !== item.id
        );
      } else {
        this.productsFavedLocal.push(item.id);
      }
      this.updateLocalStorageFaved();
    },
    getCategoryColor(categoryName) {
      return this.colorPerCategory.find((c) => c.category === categoryName)
        .color;
    },
  },
  watch: {
    filteredProducts(newValue, _) {
      this.$store.dispatch("updateNbProducts", newValue.length);
    },
  },
  destroyed() {
    bus.$off("webResume", this.loadRemoteProductsSilently);
  },
  components: {
    TrackerItem,
    Loader,
  },
};
</script>

<style scoped>
#grid-top-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 0 auto;
}

#grid-container {
  display: grid;
  gap: 1.25rem 0.75rem;
}

@media (max-width: 559.98px) {
  #grid-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 560px) and (max-width: 759.98px) {
  #grid-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 760px) and (max-width: 959.98px) {
  #grid-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 960px) and (max-width: 1159.98px) {
  #grid-container {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 1160px) {
  #grid-container {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
</style>