<template>
  <div class="search-container" ref="searchContainer">
    <div class="search">
      <span class="search-icon">&#x1F50D;</span>
      <input
        type="text"
        v-model="filter"
        placeholder="Filter by keyword..."
        class="input"
        autocomplete="off"
        @input="onChange"
      />
      <span class="clear-icon" @click="clearInput" v-show="filter.length > 0">&#x2716;</span>
    </div>
    <div class="numbers-found">
      <span style="color: rgb(243, 165, 170); display: inline">{{
        nbProducts
      }}</span>
      angel{{ nbProducts > 1 ? "s" : "" }} found
    </div>
    <div class="top-filter-container">
      <div>
        <input type="radio" id="all" value="all" v-model="topFilter" />
        <label for="all">All</label>
      </div>
      <div>
        <input type="radio" id="owned" value="owned" v-model="topFilter" />
        <label for="owned">Owned</label>
      </div>
      <div>
        <input type="radio" id="faved" value="faved" v-model="topFilter" />
        <label for="faved">Favorites</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: "",
      topFilter: "all",
    };
  },
  created() {
    this.$store.dispatch("updateTopFilter", this.topFilter);
  },
  mounted() {
    this.handleScroll(); // Initial check in case the page is already scrolled
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    nbProducts() {
      return this.$store.state.products.nbProducts;
    },
  },
  methods: {
    onChange() {
      this.$store.dispatch("updateFilter", this.filter);
      window.scrollTo(0, 0);
    },
    clearInput() {
      this.filter = "";
      this.$store.dispatch("updateFilter", this.filter);
    },
    handleScroll() {
      const searchContainer = this.$refs.searchContainer;
      if (window.pageYOffset > 50) {
        searchContainer.classList.add("sticky-shadow-add");
        searchContainer.classList.remove("sticky-shadow-remove");
      } else {
        searchContainer.classList.remove("sticky-shadow-add");
        searchContainer.classList.add("sticky-shadow-remove");
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    topFilter(newValue, _) {
      this.$store.dispatch("updateTopFilter", newValue);
    },
  },
};
</script>

<style scoped>
.search-container {
  width: 100%;
  background: white;
  position: sticky;
  /* top: 0; */
  z-index: 1000;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

@keyframes addShadow {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

@keyframes removeShadow {
  from {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  to {
    box-shadow: none;
  }
}

.sticky-shadow-add {
  animation: addShadow 0.5s forwards;
}

.sticky-shadow-remove {
  animation: removeShadow 0.5s forwards;
}

.search {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  height: 50px;
  font-size: 16px;
  background: white;
  position: relative;
  flex-grow: 1;
  outline: none;
}

.input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
}

.search-icon {
  font-size: 24px;
  color: #ccc;
  cursor: default;
  margin-right: 10px;
}

.clear-icon {
  position: absolute;
  right: 10px; /* adjust as needed */
  top: 50%;
  transform: translateY(-50%); /* vertically center */
  font-size: 24px;
  color: #ccc;
  cursor: pointer;
}

/* Style for the clear icon on hover */
.clear-icon:hover {
  color: red; /* Change to your desired color on hover */
}

.numbers-found {
  font-weight: 200;
}

.top-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  column-gap: 10px;
}
</style>