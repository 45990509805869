import bus from "../bus/bus";

class WebTools {
    constructor() {
        this.lastFocusTime = 0;
        this.cooldownDelay = -1; // cooldown period in milliseconds
    }

    init() {
        window.addEventListener("focus", () => this.onFocus("focus"));
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.onFocus("visibilitychange: visible")
            }
        });
        window.addEventListener('DOMContentLoaded', () => {
            this.onFocus("dom content loaded")
        });
    }

    onFocus(type) {
        const currentTime = Date.now();

        if (currentTime - this.lastFocusTime > this.cooldownDelay) {

            bus.$emit("webResume");
            // console.log("web resume")
            this.lastFocusTime = currentTime;
        }
    }
}

export default new WebTools()