<template>
  <div id="main-container">
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from "./components/components/General/Header.vue";
export default {
  components: {
    Header,
  },
};
</script>


<style>
@import "./styles/main.css";


#main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    row-gap: 10px;
    margin-bottom: 10px
}
</style>
