const UPDATE_NB_PRODUCTS = 'UPDATE_NB_PRODUCTS'
const UPDATE_TOP_FILTER = 'UPDATE_TOP_FILTER'
const UPDATE_FILTER = 'UPDATE_FILTER'

const state = {
  nbProducts: 0,
  topFilter: "",
  filter: "",
}

const actions = {
  updateNbProducts ({ commit }, val) {
    commit(UPDATE_NB_PRODUCTS, val)
  },
  updateTopFilter ({ commit }, val) {
    commit(UPDATE_TOP_FILTER, val)
  },
  updateFilter ({ commit }, val) {
    commit(UPDATE_FILTER, val)
  },
}

const mutations = {
  [UPDATE_NB_PRODUCTS] (state, val) {
    state.nbProducts = val
  },
  [UPDATE_TOP_FILTER] (state, val) {
    state.topFilter = val
  },
  [UPDATE_FILTER] (state, val) {
    state.filter = val
  },
}

export default {
  state,
  actions,
  mutations
}