export default [
    // "#F3A5AA",
    "#CD8FA9",
    "#A17DA0",
    "#746D8F",
    "#4D5B76",
    "#2F4858",
    "#D99877",
    "#AB9152",
    "#708A45",
    "#187F52",
    "#00706D",
    "#B97076",
    "#005B5C",
    "#47817D",
    "#574142",
    "#BFA5A6",
    "#BDBD83",
    "#878851",
    "#830041",
    "#561822",
    "#007674",
    "#AC6B82",
    "#DE90DE",
    "#A55CA7",
    "#F06074",
    "#67C9C4",
    "#28938F",
    "#54B07C",
    "#9098F0",
    "#ADAC9A",
    "#FFADC8",
    "#FFB8EE",
    "#56934B",
    "#008DCE",
    "#A0002B",
    "#FC6DA6",
    "#99586F",
    "#4B373D",
    "#D16E56",
    "#8C9236",
    "#73323A",
    "#00C9C3",
    "#C77D82",
    "#9D575D",
    "#74323A",
    "#4C0E1A",
    "#4D0F1B",
    "#E1959A",
    "#8C484F",
    "#6F2E36",
]