<template>
  <div>
    <vue-loading
      type="balls"
      color="rgb(243, 165, 170)"
      :size="{ width: size, height: size }"
    ></vue-loading>
  </div>
</template>

<script>
import { VueLoading } from "vue-loading-template";

export default {
  props: ["size"],
  components: {
    VueLoading,
  },
};
</script>

<style>
</style>