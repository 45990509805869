<template>
  <div class="product-item" :class="productItemClass">
    <div class="product-content">
      <!-- <div class="fav-container" @click="fav()">
        <img class="fav-image" :src="favImage" />
      </div> -->
      <!-- <div class="product-type">{{ item.type }}</div> -->
      <div class="product-category" :style="'background: ' + categoryColor">
        {{ item.category }}
      </div>
      <div class="product-title">{{ item.name }}</div>
      <div class="product-image-container">
        <img
          class="product-item-image"
          :src="item.url"
          :title="item.name"
          loading="lazy"
          width="150"
          height="327"
        />
      </div>
      <div class="count-container">
        <div class="left" @click="sub()">-</div>
        <div class="middle">{{ item.count }}</div>
        <div class="right" @click="add()">+</div>
      </div>
      <div @click="fav()">
        <img class="fav-image" :src="favImage" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item", "categoryColor"],
  computed: {
    productItemClass() {
      return this.item.count > 0 ? "product-item-owned" : "";
    },
    favImage() {
      const faved = this.item.faved;
      return require("../../../static/img/icons/favorites/Fav" +
        (faved ? "Full" : "Empty") +
        ".png");
    },
  },
  methods: {
    sub() {
      this.$emit("sub");
    },
    add() {
      this.$emit("add");
    },
    fav() {
      this.$emit("fav");
    },
  },
};
</script>

<style scoped>
.product-item {
  position: relative;
  margin: auto auto 0;
  max-width: 150px;
  width: 100%;
  background: #f3f3f3;
  padding: 10px;
  border-radius: 0.375rem;
  transition: all ease 0.3s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.product-item-owned {
  -webkit-box-shadow: inset 0px 0px 0px 3px rgb(243, 165, 170);
  -moz-box-shadow: inset 0px 0px 0px 3px rgb(243, 165, 170);
  box-shadow: inset 0px 0px 0px 3px rgb(243, 165, 170);
  background: #f3e3e3;
}

.product-image-container {
  min-height: 327px;
  background: white;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.product-item-image {
  border-radius: 0.375rem;
  display: block;
  height: auto;
  width: 100%;
  background: white;
}

.product-content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow: visible;
}

.product-type {
  font-size: 10px;
}

.product-category {
  font-size: 11px;
  color: white;
  background: rgb(243, 165, 170);
  padding: 4px;
}

.product-title {
}

.product-item-owned .product-title {
}

.count-container {
  width: 100%;
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  /* vertical-align: middle; */
  background: white;
  border-radius: 7px;
  border: black solid 1px;
  font-size: 18px;
}

.count-container .left,
.count-container .middle,
.count-container .right {
  flex-grow: 1;
  user-select: none;
  touch-action: manipulation;
}

.count-container .left:hover,
.count-container .right:hover {
  cursor: pointer;
  touch-action: manipulation;
}

.count-container .middle:hover {
  cursor: default;
  touch-action: manipulation;
}

.count-container .middle {
  border-left: 2px solid rgb(228, 228, 228);
  border-right: 2px solid rgb(228, 228, 228);
}

.fav-container {
  position: absolute;
  /* background: white; */
  /* border: solid 2px #c9c9c9; */
  color: white;
  top: -21px;
  right: -19px;
  padding: 0.3rem;
  border-radius: 2px;
}

.fav-container:hover {
  cursor: pointer;
}

.fav-image {
  width: 30px;
  height: 30px;
}
</style>